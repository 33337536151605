import { ref } from 'vue';

const TIMEOUT = 2000;

export const useClipboard = () => {
    const isActive = ref<boolean>(false);

    const copyToClipboard = async (text: string): Promise<void> => {
        const storage = document.createElement('textarea');
        storage.value = text;
        document.body.appendChild(storage);
        storage.select();
        storage.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(storage);
        isActive.value = true;
        
        // Reset active state after TIMEOUT
        setTimeout(() => {
            isActive.value = false;
        }, TIMEOUT);
    };

    return {
        isActive,
        copyToClipboard
    };
};

export type ClipboardService = ReturnType<typeof useClipboard>;
