import api from './axios';

import type { FeedbackPayload } from '@/models/feedback';
import type { Listing, SearchResults } from '@/models/search';
import type { AxiosResponse } from 'axios';


class SearchService {
    async predict(file: File): Promise<AxiosResponse<SearchResults>> {
        const data = new FormData();
        data.append('query_image', file);
        
        return api.post('/predict/', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    async submitFeedback(feedback: FeedbackPayload): Promise<AxiosResponse<void>> {
        return api.post('/feedback', feedback);
    }

    async getListing(id: string): Promise<AxiosResponse<Listing>> {
        return api.get(`/listing/${id}`);
    }
}

export default new SearchService();
