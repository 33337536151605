import type { QueryImage } from '@/models/search';

export const useDataUrl = () => {
    const createDataUrl = (query: QueryImage): string => 
        `data:${query.content_type};base64,${query.b64_encoded}`;

    const parseDataUrl = (dataUrl: string): QueryImage => {
        const b64_encoded = dataUrl.split(',')[0].indexOf('base64') >= 0
            ? dataUrl.split(',')[1]
            : btoa(unescape(dataUrl.split(',')[1]));

        const content_type = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        return { b64_encoded, content_type };
    };

    const createFile = (dataUrl: string, fileName: string): File => {
        const query = parseDataUrl(dataUrl);
        const byteString = atob(query.b64_encoded);
        const intArray = new Uint8Array(byteString.length);
        
        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([intArray]);
        return new File([blob], fileName, { type: query.content_type });
    };

    return {
        createDataUrl,
        parseDataUrl,
        createFile
    };
};

export type DataUrlService = ReturnType<typeof useDataUrl>;
