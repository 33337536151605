import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "results-listing"
}
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}

import ResultItem from './ResultItem.vue';

import type { Item } from '@/models/item';

interface ResultsListingProps {
  items: Item[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultsListing',
  props: {
    items: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.items)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.items.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "group w-auto border border-gray-300 rounded my-4 bg-white p-4" }, [
                _createElementVNode("p", { class: "text-xl" }, "No results found"),
                _createElementVNode("p", { class: "text-gray-600 mt-2" }, "We couldn't find any bricks or minifigures in this image. Please try again with a different image.")
              ], -1)
            ])))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item, item_rank) => {
              return (_openBlock(), _createBlock(ResultItem, _mergeProps({
                key: item.id,
                item_rank: item_rank,
                ref_for: true
              }, item), null, 16, ["item_rank"]))
            }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})