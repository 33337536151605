import { defineStore } from 'pinia'

import type { Listing } from '@/models/search'

interface ListingState {
  listings: { [key: string]: Listing };
}

export const useListingStore = defineStore('listing', {
  state: (): ListingState => ({
    listings: {}
  }),
  actions: {
    setListing(listingId: string, listing: Listing): void {
      this.listings[listingId] = listing;
    },
    getListing(listingId: string): Listing | undefined {
      return this.listings[listingId];
    }
  }
})
