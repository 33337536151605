<template>
    <SpinnerComponent v-if="loadingStore.isLoading"/>

    <div class="fixed inset-0 z-30 bg-black flex items-center justify-center h-full overflow-hidden">
        <video ref="video" autoPlay={true} playsInline={true} muted={true} class="absolute w-auto min-w-full min-h-full max-w-none"></video>
        <canvas ref="canvas" class="absolute w-auto min-w-full min-h-full max-w-none"></canvas>

        <div class="absolute flex bottom-0 min-w-full bg-black bg-opacity-50 py-4 items-center justify-center space-x-12">
            <label for="search-input-search" class="text-white inline-block cursor-pointer hover:text-gray-500" title="upload image">
                <UploadIcon class="w-8 h-8"/>
            </label>
            <input type="file" accept="image/*" @change="(event: Event) => predictFromUpload(event, canvas)" id="search-input-search" class="hidden">
            <div @click="() => predictFromVideo(video, canvas)" class="text-white inline-block cursor-pointer w-16 h-16 border-white border-2 rounded-full flex items-center justify-center" title="take a photo">
                <div class="inline-block bg-white w-14 h-14 rounded-full hover:bg-opacity-75"></div>
            </div>
            <router-link class="text-white inline-block cursor-pointer hover:text-gray-500" to="/" title="go back">
                <BackIcon class="w-8 h-8"/>
            </router-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import SpinnerComponent from '@/components/common/SpinnerComponent.vue';

import BackIcon from '@/icons/BackIcon.vue';
import UploadIcon from '@/icons/UploadIcon.vue';
import { useLoadingStore } from '@/stores/loading';

import camera from '@/composables/camera';
import { useSearch } from '@/composables/search';


const router = useRouter();
const video = ref<HTMLVideoElement | null>(null);
const canvas = ref<HTMLCanvasElement | null>(null);
const loadingStore = useLoadingStore();
const { predictFromVideo, predictFromUpload } = useSearch();

const handleKeys = async (e: KeyboardEvent): Promise<void> => {
    if (e.code === 'Space') {
        await predictFromVideo(video.value, canvas.value);
    }
};

onMounted(() => {
    if (video.value) {
        camera.startCamera(video.value).catch(() => { router.back(); });
    }
    window.addEventListener('keyup', handleKeys);
});

onBeforeUnmount(() => {
    if (video.value) {
        camera.stopCamera(video.value);
    }
    window.removeEventListener('keyup', handleKeys);
});
</script>
