import { AxiosError, AxiosResponse } from 'axios';
import posthog from 'posthog-js';
import { useRouter } from 'vue-router';

import searchService from '@/api/search.service';
import { useListingStore } from '@/stores/listing';
import { useLoadingStore } from '@/stores/loading';

import { useCanvas } from './useCanvas';
import { useDataUrl } from './useDataUrl';

import type { ImageMimeType } from './useCanvas';
import type { Listing, SearchResults } from '@/models/search';

interface ErrorResponse {
    detail: string;
}

interface SearchService {
    predictFromVideo: (video: HTMLVideoElement | null, canvas: HTMLCanvasElement | null) => Promise<void>;
    predictFromUpload: (event: Event, canvas: HTMLCanvasElement | null) => Promise<void>;
}

export const useSearch = (): SearchService => {
    const router = useRouter();
    const listingStore = useListingStore();
    const loadingStore = useLoadingStore();
    const dataUrlService = useDataUrl();
    const canvasService = useCanvas();

    const beforePredict = (): void => {
        loadingStore.startLoading();
        posthog.capture('search', { state: 'start', platform: 'web' });
    };

    const predictFromCanvas = async (
        canvas: HTMLCanvasElement,
        imgName: string,
        imgType: ImageMimeType
    ): Promise<void> => {
        const dataURI = canvasService.toDataUrl(canvas, imgType, canvasService.DEFAULT_QUALITY);
        const file: File = dataUrlService.createFile(dataURI, imgName);

        try {
            const response: AxiosResponse<SearchResults> = await searchService.predict(file);

            const listing: Listing = {
                id: response.data.listing_id,
                results: response.data,
                query: dataUrlService.parseDataUrl(dataURI)
            };

            listingStore.setListing(listing.id, listing);

            let route = `/results/${listing.id}`;
            if (listing.results.items.length > 0) {
                const firstItem = listing.results.items[0];
                route = `/results/${listing.id}/?item=${firstItem.type}/${firstItem.id}`;
            }
            
            router.push(route);
        } catch (error) {
            posthog.capture('search', { state: 'error', platform: 'web' });
            const err = error as AxiosError<ErrorResponse>;
            alert(err.response?.data?.detail || 'Unknown error');
        } finally {
            loadingStore.stopLoading();
        }
    };

    const predictFromVideo = async (video: HTMLVideoElement | null, canvas: HTMLCanvasElement | null): Promise<void> => {
        if (!video || !canvas) return;
        
        beforePredict();
        const downscaledCanvas = canvasService.createDownscaledCanvas(
            canvas, 
            video, 
            video.videoWidth, 
            video.videoHeight
        );
        await predictFromCanvas(downscaledCanvas, 'image.jpg', 'image/jpeg');
    };

    const predictFromUpload = async (event: Event, canvas: HTMLCanvasElement | null): Promise<void> => {
        if (!canvas) return;
        
        beforePredict();
        const target = event.target as HTMLInputElement;
        
        if (!target.files?.length) {
            loadingStore.stopLoading();
            return;
        }

        const imageFile = target.files[0];
        const imgUpload = document.createElement('img');
        imgUpload.src = URL.createObjectURL(imageFile);
        
        return new Promise((resolve) => {
            imgUpload.onload = async () => {
                const downscaledCanvas = canvasService.createDownscaledCanvas(
                    canvas, 
                    imgUpload, 
                    imgUpload.width, 
                    imgUpload.height
                );
                await predictFromCanvas(downscaledCanvas, imageFile.name, imageFile.type as ImageMimeType);
                resolve();
            };
        });
    };

    return {
        predictFromVideo,
        predictFromUpload
    };
};

export type { SearchService, ErrorResponse };
