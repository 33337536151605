import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import AboutView from '../views/AboutView.vue'
import HomeView from '../views/HomeView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import ResultsView from '../views/ResultsView.vue'
import SearchView from '../views/SearchView.vue'
import TOSView from '../views/TOSView.vue'

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
  }
}

interface AppRouteNames {
  HomeView: 'HomeView';
  SearchView: 'SearchView';
  ResultsView: 'ResultsView';
  AboutView: 'AboutView';
  TOSView: 'TOSView';
  NotFoundView: 'NotFoundView';
}

type AppRouteNamesType = AppRouteNames[keyof AppRouteNames];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView' as AppRouteNamesType,
    component: HomeView,
    meta: { title: 'Brickognize - easily identify LEGO bricks' }
  },
  {
    path: '/search',
    name: 'SearchView' as AppRouteNamesType,
    component: SearchView,
    meta: { title: 'Search | Brickognize' }
  },
  {
    path: '/results/:listingId',
    name: 'ResultsView' as AppRouteNamesType,
    component: ResultsView,
    meta: { title: 'Results | Brickognize' }
  },
  {
    path: "/about",
    name: "AboutView" as AppRouteNamesType,
    component: AboutView,
    meta: { title: 'About | Brickognize' }
  },
  {
    path: "/terms-of-service",
    name: "TOSView" as AppRouteNamesType,
    component: TOSView,
    meta: { title: 'Terms of Service | Brickognize' }
  },
  {
    path: '/404',
    alias: ['/:pathMatch(.*)*'],
    name: 'NotFoundView' as AppRouteNamesType,
    component: NotFoundView,
    meta: { title: 'Page not found | Brickognize' }
  },
] as const;

const DEFAULT_TITLE = 'Brickognize - easily identify LEGO bricks' as const;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  document.title = to.meta.title || DEFAULT_TITLE;
  next();
});

export type { AppRouteNames, AppRouteNamesType };
export default router;
