<template>
    <SpinnerComponent v-if="loadingStore.isLoading"/>
    <canvas ref="canvas" class="hidden"></canvas>

    <nav class="fixed w-full h-16 z-20 top-0 left-0 bg-white border-b border-gray-300 px-2 sm:px-4 py-3">
        <div class="container flex flex-wrap items-center justify-between mx-auto lg:w-1/2 md:w-full">
            <router-link to="/" class="pl-2 sm:pl-4 lg:pl-2">
                <MainLogo class="inline-block h-10 pl-1"/>
            </router-link>
            <div class="w-auto" id="navbar-default">
                <ul class="flex flex-row pr-2 sm:pr-4 lg:pr-2 mt-0 space-x-8 text-gray-600 hover:text-gray-800">
                    <li class="block hover:text-gray-400" title="upload image">
                        <label for="search-input-navbar" class="block cursor-pointer hover:text-gray-400"><UploadIcon class="inline-block w-6 h-6"/></label>
                        <input type="file" accept="image/*" @change="(event: Event) => predictFromUpload(event, canvas)" id="search-input-navbar" class="hidden">
                    </li>
                    <li v-if="hasCamera" class="block hover:text-gray-400 pr-1" title="take a photo">
                        <span v-if="isMobile">
                            <label for="search-input-navbar-mobile" class="block cursor-pointer hover:text-gray-400"><CameraIcon class="inline-block w-6 h-6"/></label>
                            <input type="file" capture="environment" accept="image/*" @change="(event: Event) => predictFromUpload(event, canvas)" id="search-input-navbar-mobile" class="hidden">
                        </span>
                        <span v-else>
                            <router-link to="/search"><CameraIcon class="inline-block w-6 h-6"/></router-link>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import MainLogo from '@/components/common/MainLogo.vue';
import SpinnerComponent from '@/components/common/SpinnerComponent.vue';

import CameraIcon from '@/icons/CameraIcon.vue';
import UploadIcon from '@/icons/UploadIcon.vue';
import { useLoadingStore } from '@/stores/loading';

import camera from '@/composables/camera';
import { useSearch } from '@/composables/search';


const canvas = ref<HTMLCanvasElement | null>(null);
const hasCamera = ref<boolean>(true);
const isMobile = ref<boolean>(true);
const loadingStore = useLoadingStore();
const { predictFromUpload } = useSearch();

onMounted(() => {
    camera.setIsMobile({ isMobile });
    camera.setHasCamera({ hasCamera });
});
</script>
