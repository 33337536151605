import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "home",
  class: "container mx-auto px-4 lg:w-1/2 md:w-full"
}
const _hoisted_2 = { class: "box text-center" }

import { AxiosError, AxiosResponse } from 'axios';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SpinnerComponent from '@/components/common/SpinnerComponent.vue';
import QueryPreview from '@/components/search/QueryPreview.vue';
import ResultsListing from '@/components/search/ResultsListing.vue';

import searchService from '@/api/search.service';
import { useListingStore } from '@/stores/listing';
import { useLoadingStore } from '@/stores/loading';

import { useDataUrl } from '@/composables/useDataUrl';

import type { Listing } from '@/models/search';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultsView',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const listingStore = useListingStore();
const loadingStore = useLoadingStore();
const dataUrlService = useDataUrl();

const getListing = async (listingId: string): Promise<void> => {
  try {
    loadingStore.startLoading();
    const response: AxiosResponse<Listing> = await searchService.getListing(listingId);
    listingStore.setListing(listingId, response.data);
  } catch (error) {
    const err = error as AxiosError;
    console.error('Failed to fetch listing:', err.message);
    router.push('/404');
  } finally {
    loadingStore.stopLoading();
  }
};

const listing = computed<Listing | undefined>(() => {
  const listingId = route.params.listingId as string;
  const existingListing = listingStore.getListing(listingId);
  
  if (!existingListing) {
    void getListing(listingId);
  }
  
  return existingListing;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loadingStore).isLoading)
      ? (_openBlock(), _createBlock(SpinnerComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            (listing.value?.results.bounding_box)
              ? (_openBlock(), _createBlock(QueryPreview, {
                  key: 0,
                  query: _unref(dataUrlService).createDataUrl(listing.value.query),
                  bounding_box: listing.value.results.bounding_box
                }, null, 8, ["query", "bounding_box"]))
              : _createCommentVNode("", true)
          ]),
          (listing.value)
            ? (_openBlock(), _createBlock(ResultsListing, {
                key: 0,
                items: listing.value.results.items
              }, null, 8, ["items"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})