import * as Sentry from "@sentry/vue"
import { createPinia } from 'pinia'
import posthog from 'posthog-js'
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'

import { LOCAL_ENV, PROD_ENV, getBackendUrl, getEnv } from '@/config/environment'

import App from './App.vue'
import router from './router/index'
import './index.css'

// Sentry constants
export const SENTRY_DSN = 'https://5c372dd7f13cb9273bdc6b9cca271950@o4507186502434816.ingest.de.sentry.io/4507187771146320';
export const DEV_SENTRY_SAMPLE_RATE = 1.0;
export const PROD_SENTRY_SAMPLE_RATE = 0.1;

function sentryEnabled(): boolean {
    return getEnv() !== LOCAL_ENV;
}

// App initialization
const env = getEnv();

const app = createApp(App)
    .use(router)
    .use(createMetaManager())
    .use(createPinia())

app.mount('#app')

// Configure PostHog
posthog.init('phc_iWqhcalblEu5YrjroCRQCTZMvLEuHhSsV71WCISAa7M', {
    api_host: 'https://eu.posthog.com',
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: true,
})

// Configure Sentry if enabled
if (sentryEnabled()) {
    Sentry.init({
      app,
      environment: env,
      dsn: SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: ((env === PROD_ENV) ? PROD_SENTRY_SAMPLE_RATE : DEV_SENTRY_SAMPLE_RATE),
      tracePropagationTargets: [getBackendUrl()],
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: ((env === PROD_ENV) ? PROD_SENTRY_SAMPLE_RATE : DEV_SENTRY_SAMPLE_RATE),
    });
}
