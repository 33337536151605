<template>
  <metainfo></metainfo>
  <div id="app" class="flex flex-col h-screen text-gray-800">
    <NavBar/>
    <div class="flex-grow bg-gray-50 mt-16">
      <router-view/>
    </div>
    <FooterComponent/>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { useMeta } from 'vue-meta';
import { useRouter } from 'vue-router';

import FooterComponent from "@/components/layout/FooterComponent.vue";
import NavBar from "@/components/layout/NavBar.vue";

const router = useRouter();

const handleKeys = (e: KeyboardEvent): void => {
  if (e.code === 'Backspace') {
    router.back();
  }
};

useMeta({
  htmlAttrs: { lang: 'en' },
  description: { content: 'Brickognize is an image search for LEGO bricks!' },
});

onMounted(() => {
  window.addEventListener('keyup', handleKeys);
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeys);
});
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
