// Environment types
export type Environment = 'local' | 'development' | 'production';
export type Hostname = 'localhost' | 'dev.brickognize.com' | 'brickognize.com';

// Host constants
export const LOCAL_HOST: Hostname = 'localhost';
export const DEV_HOST: Hostname = 'dev.brickognize.com';
export const PROD_HOST: Hostname = 'brickognize.com';

// Environment constants
export const LOCAL_ENV: Environment = 'local';
export const DEV_ENV: Environment = 'development';
export const PROD_ENV: Environment = 'production';

// Backend URL constants
export const LOCAL_BACKEND_URL = 'http://localhost:8000';
export const DEV_BACKEND_URL = 'https://api.dev.brickognize.com';
export const PROD_BACKEND_URL = 'https://api.brickognize.com';

export function getEnv(): Environment {
    const hostname = window.location.hostname;
    switch (hostname) {
        case LOCAL_HOST:
            return LOCAL_ENV;
        case DEV_HOST:
            return DEV_ENV;
        case PROD_HOST:
            return PROD_ENV;
        default:
            return PROD_ENV;
    }
}

export function getBackendUrl(): string {
    const env: Environment = getEnv();
    switch (env) {
        case LOCAL_ENV:
            return LOCAL_BACKEND_URL;
        case DEV_ENV:
            return DEV_BACKEND_URL;
        case PROD_ENV:
            return PROD_BACKEND_URL;
        default:
            return PROD_BACKEND_URL;
    }
}
