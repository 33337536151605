import { Ref } from 'vue'

interface CameraState {
    isMobile: Ref<boolean>;
    hasCamera: Ref<boolean>;
}

interface CameraError extends Error {
    name: 'CameraError';
    code?: string;
}

interface CameraService {
    setIsMobile(state: Pick<CameraState, 'isMobile'>): void;
    setHasCamera(state: Pick<CameraState, 'hasCamera'>): Promise<void>;
    startCamera(video: HTMLVideoElement): Promise<MediaStream>;
    stopCamera(video: HTMLVideoElement): void;
}

const createCameraError = (message: string, code?: string): CameraError => {
    const error = new Error(message) as CameraError;
    error.name = 'CameraError';
    error.code = code;
    return error;
};

const camera: CameraService = {
    setIsMobile({ isMobile }: Pick<CameraState, 'isMobile'>): void {
        const el = document.createElement('input');
        isMobile.value = el.capture !== undefined;
    },

    async setHasCamera({ hasCamera }: Pick<CameraState, 'hasCamera'>): Promise<void> {
        if (!navigator.mediaDevices?.enumerateDevices) {
            hasCamera.value = false;
            throw createCameraError('enumerateDevices() not supported', 'DEVICES_NOT_SUPPORTED');
        }

        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            hasCamera.value = devices.some(device => device.kind === 'videoinput');
        } catch (error) {
            hasCamera.value = false;
            throw createCameraError('Failed to enumerate devices', 'ENUMERATION_FAILED');
        }
    },

    async startCamera(video: HTMLVideoElement): Promise<MediaStream> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ 
                video: { facingMode: 'environment' } 
            });
            video.srcObject = stream;
            await video.play();
            return stream;
        } catch (error) {
            const message = error instanceof Error ? error.message : 'Unknown error';
            throw createCameraError(`Unable to access camera: ${message}`, 'CAMERA_ACCESS_FAILED');
        }
    },

    stopCamera(video: HTMLVideoElement): void {
        const stream = video.srcObject as MediaStream | null;
        stream?.getTracks().forEach(track => track.stop());
        video.srcObject = null;
    }
};

export type { CameraState, CameraService, CameraError };
export default camera
