import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative inline-block" }
const _hoisted_2 = ["src"]

import { computed } from 'vue';

import type { BoundingBox } from '@/models/search';

interface QueryPreviewProps {
    query: string;
    bounding_box: BoundingBox;
}

type StyleDimensions = Record<'left' | 'top' | 'width' | 'height', string>;

const MARGIN_PERCENTAGE = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryPreview',
  props: {
    query: {},
    bounding_box: {}
  },
  setup(__props: any) {

const props = __props;

const asPercentages = (bbox: BoundingBox): BoundingBox => ({
    ...bbox,
    left: 100 * bbox.left / bbox.image_width,
    right: 100 * bbox.right / bbox.image_width,
    upper: 100 * bbox.upper / bbox.image_height,
    lower: 100 * bbox.lower / bbox.image_height,
    image_width: 100,
    image_height: 100
});

const addMargins = (bbox: BoundingBox): BoundingBox => {
    const width = bbox.right - bbox.left;
    const height = bbox.lower - bbox.upper;
    const margin = MARGIN_PERCENTAGE * Math.max(width, height) / 100;

    return {
        ...bbox,
        left: Math.max(0, bbox.left - margin),
        upper: Math.max(0, bbox.upper - margin), 
        right: Math.min(100, bbox.right + margin),
        lower: Math.min(100, bbox.lower + margin)
    };
};

const asStyle = (bbox: BoundingBox): StyleDimensions => ({
    left: `${bbox.left}%`,
    top: `${bbox.upper}%`,
    width: `${bbox.right - bbox.left}%`,
    height: `${bbox.lower - bbox.upper}%`
});

const scaledBoundingBox = computed<StyleDimensions>(() => {
    let bbox = asPercentages(props.bounding_box);
    bbox = addMargins(bbox);
    return asStyle(bbox);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "absolute border-2 border-red-500",
      style: _normalizeStyle(scaledBoundingBox.value)
    }, null, 4),
    _createElementVNode("img", {
      id: "query-preview",
      src: _ctx.query,
      class: "mx-auto rounded",
      style: {"max-width":"200px","max-height":"200px","width":"auto","height":"auto"}
    }, null, 8, _hoisted_2)
  ]))
}
}

})