<template>
    <div class="relative inline-block">
        <div class="absolute border-2 border-red-500" :style="scaledBoundingBox"></div>
        <img id="query-preview" :src="query" class="mx-auto rounded" style="max-width: 200px; max-height: 200px; width: auto; height: auto;">
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { BoundingBox } from '@/models/search';

interface QueryPreviewProps {
    query: string;
    bounding_box: BoundingBox;
}

type StyleDimensions = Record<'left' | 'top' | 'width' | 'height', string>;

const MARGIN_PERCENTAGE = 5;

const props = defineProps<QueryPreviewProps>();

const asPercentages = (bbox: BoundingBox): BoundingBox => ({
    ...bbox,
    left: 100 * bbox.left / bbox.image_width,
    right: 100 * bbox.right / bbox.image_width,
    upper: 100 * bbox.upper / bbox.image_height,
    lower: 100 * bbox.lower / bbox.image_height,
    image_width: 100,
    image_height: 100
});

const addMargins = (bbox: BoundingBox): BoundingBox => {
    const width = bbox.right - bbox.left;
    const height = bbox.lower - bbox.upper;
    const margin = MARGIN_PERCENTAGE * Math.max(width, height) / 100;

    return {
        ...bbox,
        left: Math.max(0, bbox.left - margin),
        upper: Math.max(0, bbox.upper - margin), 
        right: Math.min(100, bbox.right + margin),
        lower: Math.min(100, bbox.lower + margin)
    };
};

const asStyle = (bbox: BoundingBox): StyleDimensions => ({
    left: `${bbox.left}%`,
    top: `${bbox.upper}%`,
    width: `${bbox.right - bbox.left}%`,
    height: `${bbox.lower - bbox.upper}%`
});

const scaledBoundingBox = computed<StyleDimensions>(() => {
    let bbox = asPercentages(props.bounding_box);
    bbox = addMargins(bbox);
    return asStyle(bbox);
});
</script>
