<template>
<a class="min-w-max text-center" :href="external_sites[0].url" target="blank" @click="sendFeedback('external-site')">
  <div class="group w-auto border border-gray-300 hover:shadow-lg rounded my-4 bg-white">
    <div class="border-b border-gray-300 py-4 px-2">
      <p class="text-xl">{{ name }}</p>
      <div class="flex flex-wrap justify-center items-center space-x-2 mt-2">
        <span class="text-gray-600 text-lg">Bricklink ID: {{ id }}</span>
        <CopyIcon @click.stop.prevent="copyItemId" class="text-xl" :class="{ 'text-red-500': isActive, 'text-gray-600': !isActive, 'hover:text-gray-800': !isActive}"/>
      </div>
    </div>
    <img class="p-4 inline mx-auto" :src="img_url" @error="replaceBrokenImage" />
  </div>
</a>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';

import searchService from '@/api/search.service';
import CopyIcon from '@/icons/CopyIcon.vue';

import { useClipboard } from '@/composables/useClipboard';
import { useImage } from '@/composables/useImage';

import type { FeedbackPayload, FeedbackSource } from '@/models/feedback';
import type { Item } from '@/models/item';

interface ResultItemProps extends Item {
  item_rank: number;
}

const props = defineProps<ResultItemProps>();

const route = useRoute();
const { isActive, copyToClipboard } = useClipboard();
const { replaceBrokenImage } = useImage();

const sendFeedback = async (source: FeedbackSource): Promise<void> => {
  const payload: FeedbackPayload = {
    listing_id: route.params.listingId as string,
    item_id: props.id,
    item_type: props.type,
    is_prediction_correct: true,
    source: source,
    item_rank: props.item_rank
  };
  
  await searchService.submitFeedback(payload).catch(() => {});
};

const copyItemId = async (): Promise<void> => {
  await copyToClipboard(props.id);
  await sendFeedback('copy-btn');
};
</script>
