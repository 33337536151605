<template>
  <div id="home" class="container mx-auto px-4 lg:w-1/2 md:w-full">
    <SpinnerComponent v-if="loadingStore.isLoading"/>
    <template v-else>
      <div class="box text-center">
        <QueryPreview v-if="listing?.results.bounding_box" 
          :query="dataUrlService.createDataUrl(listing.query)"
          :bounding_box="listing.results.bounding_box"
        />
      </div>
      <ResultsListing v-if="listing" :items="listing.results.items"/>
    </template>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SpinnerComponent from '@/components/common/SpinnerComponent.vue';
import QueryPreview from '@/components/search/QueryPreview.vue';
import ResultsListing from '@/components/search/ResultsListing.vue';

import searchService from '@/api/search.service';
import { useListingStore } from '@/stores/listing';
import { useLoadingStore } from '@/stores/loading';

import { useDataUrl } from '@/composables/useDataUrl';

import type { Listing } from '@/models/search';

const route = useRoute();
const router = useRouter();
const listingStore = useListingStore();
const loadingStore = useLoadingStore();
const dataUrlService = useDataUrl();

const getListing = async (listingId: string): Promise<void> => {
  try {
    loadingStore.startLoading();
    const response: AxiosResponse<Listing> = await searchService.getListing(listingId);
    listingStore.setListing(listingId, response.data);
  } catch (error) {
    const err = error as AxiosError;
    console.error('Failed to fetch listing:', err.message);
    router.push('/404');
  } finally {
    loadingStore.stopLoading();
  }
};

const listing = computed<Listing | undefined>(() => {
  const listingId = route.params.listingId as string;
  const existingListing = listingStore.getListing(listingId);
  
  if (!existingListing) {
    void getListing(listingId);
  }
  
  return existingListing;
});
</script>
