import { defineStore } from 'pinia'

interface LoadingState {
  isLoading: boolean;
}

export const useLoadingStore = defineStore('loading', {
  state: (): LoadingState => ({
    isLoading: false
  }),
  actions: {
    setLoading(value: boolean): void {
      this.isLoading = value
    },
    startLoading(): void {
      this.isLoading = true
    },
    stopLoading(): void {
      this.isLoading = false
    }
  }
})
