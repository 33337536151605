import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white border-t border-gray-300 px-2 py-4" }
const _hoisted_2 = { class: "container flex flex-wrap items-center justify-between mx-auto lg:w-1/2 md:w-full" }
const _hoisted_3 = { class: "pl-2 pt-1 pb-1 text-sm text-gray-600 sm:text-center" }
const _hoisted_4 = { class: "flex flex-wrap items-center text-sm text-gray-600 hover:text-gray-800 pl-2 pt-1 pb-1" }
const _hoisted_5 = ["href"]

import { computed } from 'vue';

import { getBackendUrl } from '@/config/environment';


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  setup(__props) {

const backendDocsUrl = computed<string>(() => `${getBackendUrl()}/docs`);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, " © " + _toDisplayString(new Date().getFullYear()) + " Brickognize ", 1),
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "mr-4 md:mr-6 hover:underline",
            to: "/about"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("About")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            class: "mr-4 md:mr-6 hover:underline",
            to: "/terms-of-service"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Terms of Service")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            class: "mr-4 md:mr-6 hover:underline",
            href: backendDocsUrl.value
          }, "API", 8, _hoisted_5)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("li", null, [
          _createElementVNode("a", {
            class: "mr-4 md:mr-6 hover:underline",
            href: "mailto:piotr.rybak@brickognize.com"
          }, "Contact")
        ], -1))
      ])
    ])
  ]))
}
}

})