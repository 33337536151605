type ImageSource = HTMLImageElement | HTMLVideoElement;
type ImageMimeType = 'image/jpeg' | 'image/png' | 'image/webp';

interface CanvasConfig {
    width: number;
    height: number;
    maxSize?: number;
}

const DEFAULT_MAX_SIZE = 1024;
const DEFAULT_QUALITY = 0.95;

export const useCanvas = () => {
    const calculateScaleProportions = ({ width, height, maxSize = DEFAULT_MAX_SIZE }: CanvasConfig): number => {
        if (width <= maxSize && height <= maxSize) return 1;
        return height <= width ? maxSize / width : maxSize / height;
    };

    const createDownscaledCanvas = (
        canvas: HTMLCanvasElement,
        imgSrc: ImageSource,
        srcWidth: number,
        srcHeight: number,
        maxSize: number = DEFAULT_MAX_SIZE
    ): HTMLCanvasElement => {
        const context = canvas.getContext('2d', { alpha: true });
        if (!context) throw new Error('Could not get canvas context');

        const config: CanvasConfig = {
            width: srcWidth,
            height: srcHeight,
            maxSize
        };

        const scaleProportions = calculateScaleProportions(config);
        canvas.width = srcWidth * scaleProportions;
        canvas.height = srcHeight * scaleProportions;
        context.drawImage(imgSrc, 0, 0, canvas.width, canvas.height);

        return canvas;
    };

    const toDataUrl = (canvas: HTMLCanvasElement, type: ImageMimeType, quality = DEFAULT_QUALITY): string => 
        canvas.toDataURL(type, quality);

    return {
        createDownscaledCanvas,
        toDataUrl,
        DEFAULT_QUALITY
    };
};

export type { ImageSource, ImageMimeType };
export type CanvasService = ReturnType<typeof useCanvas>;
