import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "flex flex-col h-screen text-gray-800"
}
const _hoisted_2 = { class: "flex-grow bg-gray-50 mt-16" }

import { onMounted, onUnmounted } from 'vue';
import { useMeta } from 'vue-meta';
import { useRouter } from 'vue-router';

import FooterComponent from "@/components/layout/FooterComponent.vue";
import NavBar from "@/components/layout/NavBar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

const handleKeys = (e: KeyboardEvent): void => {
  if (e.code === 'Backspace') {
    router.back();
  }
};

useMeta({
  htmlAttrs: { lang: 'en' },
  description: { content: 'Brickognize is an image search for LEGO bricks!' },
});

onMounted(() => {
  window.addEventListener('keyup', handleKeys);
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleKeys);
});

return (_ctx: any,_cache: any) => {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(NavBar),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(FooterComponent)
    ])
  ], 64))
}
}

})