export const BROKEN_IMAGE_PLACEHOLDER = 'https://storage.googleapis.com/brickognize-static/static/missing-image.png';

export const useImage = () => {
  const replaceBrokenImage = (event: Event): void => {
    const target = event.target as HTMLImageElement;
    target.src = BROKEN_IMAGE_PLACEHOLDER;
  };

  return {
    replaceBrokenImage
  };
};
