<template>
    <footer class="bg-white border-t border-gray-300 px-2 py-4">
        <div class="container flex flex-wrap items-center justify-between mx-auto lg:w-1/2 md:w-full">
            <span class="pl-2 pt-1 pb-1 text-sm text-gray-600 sm:text-center">
                © {{ new Date().getFullYear() }} Brickognize
            </span>
            <ul class="flex flex-wrap items-center text-sm text-gray-600 hover:text-gray-800 pl-2 pt-1 pb-1">
                <li><router-link class="mr-4 md:mr-6 hover:underline" to="/about">About</router-link></li>
                <li><router-link class="mr-4 md:mr-6 hover:underline" to="/terms-of-service">Terms of Service</router-link></li>
                <li><a class="mr-4 md:mr-6 hover:underline" :href="backendDocsUrl">API</a></li>
                <li><a class="mr-4 md:mr-6 hover:underline" href="mailto:piotr.rybak@brickognize.com">Contact</a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
import {getBackendUrl} from '@/main';

export default {
    name: "FooterComponent",
    computed: {
      backendDocsUrl () {
        return `${getBackendUrl()}/docs`
      }
    }
};
</script>